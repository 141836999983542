var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_details diy_div_commodity_management"},[_c('div',{staticClass:"warp"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.$check_field('get','trade_name'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(0),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["trade_name"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','category'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(1),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["category"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','picture'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(2),_c('div',{staticClass:"diy_field diy_img"},[_c('img',{attrs:{"src":_vm.$fullUrl(_vm.obj['picture'])}})])])]):_vm._e(),(_vm.$check_field('get','unit_price'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(3),_c('div',{staticClass:"diy_field diy_number"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["unit_price"])+" ")])])])]):_vm._e(),(_vm.$check_field('get','remarks'))?_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(4),_c('div',{staticClass:"diy_field diy_desc"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["remarks"])+" ")])])])]):_vm._e(),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"view"},[_vm._m(5),_c('div',{staticClass:"diy_field diy_text"},[_c('span',[_vm._v(" "+_vm._s(_vm.obj["praise_len"])+" ")])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("商品名称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("类别")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("图片")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("单价")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("备注")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"diy_title"},[_c('span',[_vm._v("点赞数")])])
}]

export { render, staticRenderFns }